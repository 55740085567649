@import "../../styles/variables.scss";

.btn_game {
  padding: 30px 0;
    border: 2px solid #000;
    border-radius: 11px;
    display: inline-block;
    font-weight: bold;
    color: $white;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0px;
    cursor: pointer;
  }
  .secondEffect{
    opacity:0;
    transform: translateY(20px);
    width: 90%;
  }
  .header-btn {
    font-size: 58px;
    color: $white !important;
  }
  
  .bottom-btn {
    font-size: 32px;
    color: $white;
  }
  
  .btn_game:before {
    content: "";
    position: absolute;
    top: 0;
    left: -13px;
    width: 100%;
    opacity: 1;
    height: 1px;
    background-color: transparent;
    box-shadow: 1px 0px 14px 7px $white;
    padding: 0 10px;
    margin: 0 10px;
    z-index: 1;
    opacity: .9;
  }
  
  .btn_game:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -13px;
    width: 100%;
    height: 1px;
    background-color: transparent;
    box-shadow: 1px 0px 14px 7px $white;
    padding: 0 10px;
    margin: 0 10px;
    z-index: 10;
    opacity: .9;
  }
  
  .btn_bg_color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $bunker ;
    opacity: .55;
    z-index: 0;
  }
  
  .btn_container {
    color: $white;
    position: relative;
    z-index: 1;
  }
  
  
  /*new below*/
  .four-buttons-item{
    overflow: hidden;
    
  }
  
.effect_click{
    transform: scale(1) translateY(0px);
    transition: .35s;
  
  }
  .effect_click:hover{
    transform: scale(.95) translateY(5px);
    transition: .35s;
  }
.secondEffect{
  transform: translateY(20px);
  opacity:0;
  width: 90%
}
.secondEffect.show_button {
    animation-name: show_button_anime;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.33, 0.19, 0.04, 2.07);
    animation-fill-mode: forwards;
  }
  @keyframes show_button_anime {
    from    {
      transform: translateY(20px);
      opacity:0;
    }
    to {
      transform: translateY(0);
      opacity:1;
    }
  }
  