@import "./outside.module";
@import "./Home.module";

.mergeteam-wrapper {
  width: 90%;
  margin: 10px 5%;
  display: block;
  position: relative;
}

.mergeteam-container {padding: 20px;color: #fff;display: block;position: relative;height: 1000px;width: 932px; margin: auto;}
.mergeteam-card {
  display: inline-block;
  position: absolute;
  top: 72%;
  left: 48%;
  transform: translate(-50%, -50%);
}

.mergeteam-1 {
  position: absolute;
  top: 11%;
  left: -14%;
}
.mergeteam-2 {
  position: absolute;
  top: 11%;
  left: 53%;
}
.mergeteam-3 {
  position: absolute;
  top: 45%;
  left: -14%;
}
.mergeteam-4 {
  position: absolute;
  top: 45%;
  left: 53%;
}
.mergeteam-5 {
  position: absolute;
  top: -7%;
  left: 5%;
}
.mergeteam-6 {
  position: absolute;
  top: -7%;
  left: 32%;
}
.card-name-container {
  position: absolute;
  bottom: 130px;
  left: calc(50% - 4px);
  transform: translateX(-50%);
  width: 250px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-name{
  font-weight: bold;
  font-size: 50px;
}
.mergeteam-blue .card-name{
  color: #01435e;
}
.mergeteam-container .card-name-container{
  position: absolute;
  bottom: 90px;
  left: calc(50% - 4px);
  transform: translateX(-50%);
  width: 250px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mergeteam-item {
  width: 600px;
  height: 600px;
}
.mergeteam-item video.merge-team-flame {
  position: absolute;
  width: 100px;
  height: 100px;
}
.mergeteam-1 video.merge-team-flame {
  top: 144px;
  left: 231px;
}
.mergeteam-2 video.merge-team-flame {
  top: 144px;
  left: 269px;
}
.mergeteam-3 video.merge-team-flame {
  top: 150px;
  left: 235px;
}
.mergeteam-4 video.merge-team-flame {
  top: 150px;
  left: 269px;
}
.mergeteam-5 video.merge-team-flame {
  top: 149px;
  left: 200px;
}
.mergeteam-6 video.merge-team-flame {
  top: 145px;
  left: 305px;
}
.mergeteam-helptext-container{
  margin: 0 auto;
  width: 100%;

}
.mergeteam-helptext{
  color: #1be1f0;
  font-size: 40px;
  text-align: center;
  padding-bottom: 30px;
}

.levels-wrapper {
  width: 90%;
  margin: 10px 5%;
  display: block;
  position: relative;
}

.levels-container {
  padding: 20px;
  position: relative;
  color: #fff;
}

.levels-wrapper:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .3;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.levels-text {
  line-height: 34px;
  font-size: 30px;
}

.levels-divider {
  width: 35%;
  height: 2px;
  background-color: #fff;
  margin: 15px auto;
}

.levels-header {
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  margin: 20px auto;
}

.levels-all-items {
  display: inline-flex;
}

.levels-all-items {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.levels-item-header {
  color: #1be1f0;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}

.levels-item-header span {
  font-weight: normal;
}

.levels-item {
  margin: 0 20px;
}

.levels-clock-image {
  text-align: center;
}

.levels-clock-image img {
  max-width: 100px;
}

.levels-clock {
  position: relative;
}

.levels-clock-time {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  line-height: 30px;
  text-align: center;
}

.levels-clock-hms {
  font-size: 18px;
  line-height: 18px;
}

.levels-clock {
  margin: 10px 0;
}

.card-wrapper {
  width: 90%;
  margin: 10px 5%;
  display: block;
  position: relative;
}

.card-container {
  padding: 20px;
  position: relative;
  color: #fff;
}
.card-item {
  position: relative;
  min-height: 550px;
  min-width: 380px;
  display: block;
  width: auto;
  display: inline-block;
  margin: 0 auto;
}
.card-video-flame{
  max-width: 140px;
  position: absolute;
  top: -50px;
  left: -50px;
}

img.card-img-transparent, .card-img {
  position: absolute;
  top: 0;
  left:0;
}

.card-container {
  text-align: center;width: 100%;
}
.card-name-container {
  position: absolute;
  bottom: 130px;
  left: calc(50% - 4px);
  transform: translateX(-50%);
  width: 250px;
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-name{
  font-weight: bold;
  font-size: 50px;
}

.blue-card .card-name{
  color: #01435e;
}
video.room-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  height: auto;
  left: calc( 50% - 4px);
  transform: translateX(-50%);
}
.skills-container {
  padding: 20px;
  position: relative;
  color: #fff;
  display: inline-flex;
  width: 90%;
  justify-content: space-around;
}

.skill-item {
  display: inline-flex;
  align-items: center;
}

.skill-score {
  border-radius: 20px;
  border: 1px solid #fff;
  padding: 5px;
}

.point {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  background-color: #ffffff3b;
  position: relative;
}

.point.achieved {
  background-color: #fff;
}

.skill-text {
  margin: 0 5px;
  font-size: 22px;
}


