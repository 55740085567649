.the-maze-inside-wrapper {
  font-family: Agency;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  &.blue {
    background-image: url("../../assets/inside/blue/CMN_01b.PNG");
  }
  &.red {
    background-image: url("../../assets/inside/red/CMN_01r.PNG");
  }
  &.yellow {
    background-image: url("../../assets/inside/yellow/CMN_01y.PNG");
  }
}
.the-maze-inside-wrapper .video_bg{
  position:relative;
  height: 100%;
  width:auto;
  display: block;
}
.the-maze-inside-wrapper .video_bg source{
  position:absolute;
  width: 100%;
  height: 100%;
}
