@import "../../styles/variables.scss";

.the-maze-wrapper {
  background-color: grey;
  font-family: Agency;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/images/BG.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden
}

.four-buttons-item {
  color: #fff;
  display: inline-flex;
  width: 90%;
  text-align: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.four-buttons-container {
  display: inline-flex;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}



.effect_click {
  transform: scale(1) translateY(0px);
  transition: .35s;

}
.effect_click:hover{
  transform: scale(.95) translateY(5px);
  transition: .35s;
}
