//inside-countdown

.countdown-inside-right {
  width: 100%;
  display: block;
  text-align: right;
  margin-top: 20px;
  bottom: 70px;
  right: 20px;
  position: absolute;
}

.countdown-inside{
  display: inline-block;
  background-color: rgb(1,1,1,.5);
  padding: 10px 30px;
  border-radius: 30px;
  color: #1be1f0;
  font-size: 40px;
  font-weight: bold;
}

//colors

.active-blue{
  color: #0bc9d7;
}
.active-red{
  color: #ff0000;
}
.active-yellow{
  color: #ffb000;
}

//inside

html, body, #root {
  height:100%;
  margin: 0;
  padding: 0;
}
.inside-wrapper {
  font-family: Agency;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden
}
.inside-container {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.inside-wrapper .video_bg{
  position:relative;
  height: 100%;
  width:auto;
  display: block;
}
.inside-wrapper .video_bg source{
  position:absolute;
  width: 100%;
  height: 100%;
}



.inside-top-inner{
  display: inline-flex;
  width: 100%;
}
.inside-top-left{
  width: 25%;
}
.inside-top-middle{
  width: 50%;
}
.inside-top-right{
  width: 25%;
  text-align: right;
}
.inside-bottom-outer{
  text-align: center;
}
.inside-bottom-inner{
  max-width: 1550px;
  margin: 0 auto;
}

.inside-top.inside-top-middle{
  text-align: center;
}
.score-container {
  display: inline-block;
  position: relative;
}
.score-container img{
  max-width: 350px;
}
.score-number{
  position:absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 80px;
  font-weight: bold;
}
.score-header{
  position:absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 25px;
  font-weight: bold;
}
.difficulty-block {
  display: inline-block;
  font-size: 40px;
  background-color: rgba(0,0,0,.5);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px 30px 20px 20px;
}
.inside-top.inside-top-left {
  display: inline-flex;
}
.inside-top.inside-top-right {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-end;
  font-weight: bold;
}
.difficulty-block-title{
  color: #fff;
  text-align: center;
  font-weight: normal;
}
.inside-wrapper.blue-screen .difficulty-block-header{
  color: #0bc9d7;
}

.inside-wrapper.red-screen .difficulty-block-header{
  color: #ff0000;
}

.inside-wrapper.yellow-screen .difficulty-block-header{
  color: #ffb000;
}
.inside-wrapper.blue-screen .score-header{
  color: #232039;
}
.inside-wrapper.blue-screen .score-number{
  color: #0bc9d7;
}

.inside-wrapper.red-screen .score-number{
  color: #ff0000;
}

.inside-wrapper.yellow-screen .score-number{
  color: #ffb000;
}

.inside-wrapper.inside-difffculty-wrapper .card-wrapper{
  margin:0;
  position: relative
}
.inside-the-maze-team-container {
  position: relative;
  display: inline-block;
  width: 230px;
  height: 90px;
}
.inside-the-maze-team-img{
}
.inside-the-maze-team-text{
  position: absolute;
  top: calc( 50% - 3px);
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 25px;
}
.inside-top-outer {
  margin-top: 50px
}

//card-difficulty

.inside-difffculty-wrapper .card-wrapper {
  width: auto;
  margin: 10px 5%;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
}

.inside-difffculty-wrapper .card-container {
  padding: 20px;
  position: relative;
  color: #fff;
}
.inside-difffculty-wrapper .card-item {
  position: relative;
  min-height: 350px;
  min-width: 217px;
  /* display: block; */
  width: auto;
  display: inline-block;
  margin: 0 auto;
  /* max-width: 268px; */
  /* max-height: 300px; */
}
.inside-difffculty-wrapper .card-video-flame{
  max-width: 100px;
  position: absolute;
  top: -50px;
  left: -50px;
}

.inside-difffculty-wrapper img.card-img-transparent, .inside-difffculty-wrapper .card-img {
  position: absolute;
  top: 0;
  left:0;
  width: 100%;
  height: auto;
}

.inside-difffculty-wrapper .card-container {
  text-align: center;width: 100%;
}
.inside-difffculty-wrapper .card-name-container {
  position: absolute;
  bottom: 97px;
  left: calc(50% - 4px);
  transform: translateX(-50%);
  width: 140px;
  height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inside-difffculty-wrapper .card-name{
  font-weight: bold;
  font-size: 25px;
}

.inside-difffculty-wrapper .blue-card .card-name{
  color: #01435e;
}
.inside-difffculty-wrapper video.room-video {
  position: absolute;
  top: 27px;
  left: 0;
  width: 100px;
  height: auto;
  left: calc( 50% - 4px);
  transform: translateX(-50%);
}
//inside-countdown




.inside-bottom-inner  .outside-modal-countdown{
  text-align: center;
}
.inside-bottom-inner  .btn_game{
  border: 2px solid #000;
  border-radius: 11px;
  display: inline-block;
  font-weight: bold;
  color: #fff;
  position: relative;
  overflow: hidden;
  width: auto;
  margin: 0 20px;
  cursor: pointer;
  padding: 14px 10% !important;
  position: relative;
  overflow-y: hidden;
  display: inline-block;
}
.inside-bottom-inner  .btn_game:before {
  content: "";
  position: absolute;
  top: 0;
  left: -13px;
  width: 100%;
  opacity: 1;
  height: 1px;
  background-color: transparent;
  box-shadow: 1px 0px 14px 7px #fff;
  padding: 0 10px;
  margin: 0 10px;
  z-index: 1;
  opacity: .9;
}

.inside-bottom-inner  .btn_game:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -13px;
  width: 100%;
  height: 1px;
  background-color: transparent;
  box-shadow: 1px 0px 14px 7px #fff;
  padding: 0 10px;
  margin: 0 10px;
  z-index: 10;
  opacity: .9;
}
.inside-bottom-inner  .btn_bg_color {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: .2;
  z-index: 0;
}

.inside-bottom-inner  .btn_container {
  color: #fff;
  position: relative;
  z-index: 1;
}
.inside-bottom-inner .header-btn {
  font-size: 80px;
  color: #fff !important;
  padding: 0 40px;
}
//inside-result

.teams-point {
  background-color: rgba(0,0,0,0.5);
  display: inline-block;
  color: #fff;
  padding: 5px 40px;
  font-size: 30px;
  border-radius: 40px;
  margin-top: 20px;
}
.inside-bottom-inner .win-lose-button.you-win-button .btn_game{
  border: 2px solid #00be00 !important;
  color: #00be00 !important;
}
.inside-bottom-inner .win-lose-button.you-win-button .btn_game:before {
  background-color: transparent;
  box-shadow: 1px 0px 14px 7px #00be00 !important;
}

.inside-bottom-inner .win-lose-button.you-win-button .btn_game:after {
  box-shadow: 1px 0px 14px 7px #00be00 !important;
}
.inside-bottom-inner .win-lose-button.you-win-button .btn_bg_color {
  background-color: #00be00 !important;
}

.inside-bottom-inner .win-lose-button.you-win-button .btn_container {
  color: #00be00 !important;
}
.inside-bottom-inner .win-lose-button.you-win-button .header-btn {
  color: #00be00 !important;
}
.inside-bottom-inner .win-lose-button.you-win-button .btn_game:before{
  top: -5px;
}
.inside-bottom-inner .win-lose-button.you-win-button .btn_game:after{
  bottom: -2px;
}

.inside-bottom-inner .win-lose-button.you-lose-button .btn_game{
  border: 2px solid #ff0000 !important;
  color: #00be00 !important;
}
.inside-bottom-inner .win-lose-button.you-lose-button .btn_game:before {
  background-color: transparent;
  box-shadow: 1px 0px 14px 7px #ff0000 !important;
}

.inside-bottom-inner .win-lose-button.you-lose-button .btn_game:after {
  box-shadow: 1px 0px 14px 7px #ff0000 !important;
}
.inside-bottom-inner .win-lose-button.you-lose-button .btn_bg_color {
  background-color: #ff0000 !important;
}

.inside-bottom-inner .win-lose-button.you-lose-button .btn_container {
  color: #ff0000 !important;
}
.inside-bottom-inner .win-lose-button.you-lose-button .header-btn {
  color: #ff0000 !important;
}
.inside-bottom-inner .win-lose-button.you-lose-button .btn_game:before{
  top: -5px;
}
.inside-bottom-inner .win-lose-button.you-lose-button .btn_game:after{
  bottom: -2px;
}

//panic-button

.panic-button-container {
  display: inline-flex;
  width: 170px;
  height: 120px;
  position: relative;
  align-items: center;
  justify-content: center;
  /* margin-top: 50px; */
}
.panic-button-header{
  position: absolute;
  top: -30px;
  left: calc( 50% + 5px );
  transform: translate(-50%, -50%);
}
.panic-button-img{
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.panic-button-container img{
  width: 100%;
  height: auto;
}
.inside-home-panic-container{
  position: fixed;
  bottom:0;
  display: inline-flex;
  align-items: flex-start;
  cursor: pointer;
}
.home-button{
  display: inline-block;
  background-color: rgba(0,0,0,0.5);
  padding: 5px 50px;
  border-radius: 40px;
  cursor: pointer;
}





