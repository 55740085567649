@import "../../../../styles/variables.scss";
.overlay-wrapper {
  display: none;
}
.modal{
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
}
.opened-overlay .overlay-wrapper {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 200;
}

/*
.bg-overlay {
  background-color: #193e6b;
  opacity: .7;
  width: 300%;
  height: 100%;
  left:-100%;
  top: 0;
  position: absolute;
}*/

.container-overlay-big {
  padding: 20px;
  position: fixed;
  left: 50%;
  max-width: 1350px;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
  transition: 1s;
  z-index: 1000;
  top: 44%;
  padding: 0;
}

.modal .container-overlay-big{
  animation: moveOverlay .75s forwards;
}

@keyframes moveOverlay {
  from {
    top: 20%;
    opacity:1;
  }
  to {
    top: 40%;
    opacity:1;}
}
.close-overlay .container-overlay-big{  
  animation: closeOverlay .75s forwards;
}
@keyframes closeOverlay {
  from {
    top: 40%;
    opacity:1;
  }
  to {
    top: 20%;
    opacity:0;
  }
}
.bg-overlay{
  height: 100%;
  width: 300%;
  left: -150%;
  position: absolute;
  background-color: #ab7ee3;
  opacity: 0.3;
}
.container-overlay {
  z-index: 300;
  text-align: center;
  overflow-y: scroll;
  position: relative;
  height: 100%;
  max-height: 700px;
  background-color: #efefef;
    border: 4px solid #333959;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
}

.container-overlay-inside {
  background-color: #efefef;
  margin: 0;
}

.header-overlay {
  color: #473579;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0 0;
}


.overlay-item {
  background-color: #fff;
  padding: 20px 20%;
  margin: 0 20px 20px 20px;
  border-radius: 8px;
}

.overlay-item {
  text-align: center;
}

.overlay-item-title {
  color: #000000;
  padding-bottom: 5px;
  font-weight: bold;
}

.overlay-item-title:after {
  content: "";
  display: inline-block;
  background-color: #d7d7d7;
  width: 100%;
  height: 2px;
}

.overlay-item-description {
  color: #707070;
  padding-bottom: 5px;
  display: inline-flex;
  align-items: center;
}

.color-for-the-site {
  width: 17px;
  height: 17px;
  display: inline-block;
  border-radius: 50%;
  margin: 0 10px;
}

.blue-color {
  background-color: blue;
}

.yellow-color {
  background-color: yellow;
}

.heavy-blue-color {
  background-color: blue;
}

.grey-color {
  background-color: grey;
}

.light-grey-color {
  background-color: grey;
}

.close-overlay-btn {
  text-align: center;
  cursor: pointer;
  color: blue;
  font-size: 22px;
  text-decoration: underline;
  padding-bottom: 20px;
  display: inline-block;
  margin: 0 auto;
  position: absolute;
  top: -14px;
  right: 45px;
  z-index: 1000;
}


.container-overlay-big ::-webkit-scrollbar {
  width: 10px;
}

.container-overlay-big ::-webkit-scrollbar-track {
  background: white-close;
  margin: 20px 0;
}

.container-overlay-big ::-webkit-scrollbar-thumb {
  background: #25243d;
}

.container-overlay-big ::-webkit-scrollbar-thumb:hover {
  background: #25243d;
}

.text-overlay{
  padding: 0 50px;
  text-align: justify;
  font-size: 18px;
  line-height: 22px;
}
.close-overlay{
  color: #473579;
  display: block;
  overflow: hidden;

}
/*end css for overlay*/