.select-room-container {
  font-family: 'Agency', serif;
  font-size: 1.5rem;
  text-align: center;
  color: white;
}

.select-room-container ul {
  list-style-type: none;
  padding: 0px;
}
.select-room-container li {
  padding: .3em .3em .3em .3em;
  margin: .5em 0;
  text-decoration: none;
  border-radius: .3em;
  transition: .3s ease-out;
  cursor: pointer;
}