

.the-maze-wrapper {
  background-color: grey;
  font-family: Agency;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/BG.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden
}

