
.outside-modal-wrapper {
  font-family: Agency;
  width: 100%;
  height: 100%;
  background-color: #262324;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.outside-modal-container {
  max-width: 1550px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.outside-modal-text-block p {
  color: #1be1f0;
  font-size: 88px;
  font-weight: bold;
  margin: 0 170px !important;
  padding: 0 !important;
  line-height: 100px;
}
.outside-modal-logo-outer {
  text-align: center;
  margin: 20px auto;
}
.outside-modal-texts-outer{
  text-align: center;
  margin: 20px auto;

}

.outside-modal-text-block {
  margin: 100px auto;
}
p.outside-modal-action:after {
  content:"";
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #1be1f0;
  display: inline-block;
  position: relative;
  right: -14px;
}
.outside-modal-logo {
  margin: 20% auto  50px;
}
.outside-modal-logo img{
  width: 200px;
  height: auto;
}


.outside-modal-countdown-outer .outside-modal-countdown{
  text-align: center;
}
.outside-modal-countdown-outer .btn_game{
  border: 2px solid #000;
  border-radius: 11px;
  display: inline-block;
  font-weight: bold;
  color: #fff;
  position: relative;
  overflow: hidden;
  width: auto;
  margin: 0 20px;
  cursor: pointer;
  padding: 14px 30px !important;
  position: relative;
  overflow-y: hidden;
  display: inline-block;
}
.outside-modal-countdown-outer .btn_game:before {
  content: "";
  position: absolute;
  top: 0;
  left: -13px;
  width: 100%;
  opacity: 1;
  height: 1px;
  background-color: transparent;
  box-shadow: 1px 0px 14px 7px #fff;
  padding: 0 10px;
  margin: 0 10px;
  z-index: 1;
  opacity: .9;
}

.outside-modal-countdown-outer .btn_game:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -13px;
  width: 100%;
  height: 1px;
  background-color: transparent;
  box-shadow: 1px 0px 14px 7px #fff;
  padding: 0 10px;
  margin: 0 10px;
  z-index: 10;
  opacity: .9;
}
.outside-modal-countdown-outer .btn_bg_color {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: .2;
  z-index: 0;
}

.outside-modal-countdown-outer .btn_container {
  color: #fff;
  position: relative;
  z-index: 1;
}
.outside-modal-countdown-outer .header-btn {
  font-size: 80px;
  color: #fff !important;
  padding: 0 40px;
}
