html, body, #root {
  height:100%;
  margin: 0;
  padding: 0;
}
#root {
  overflow: hidden;
  position: relative;
}

@font-face {
  font-family: 'Agency';
  src: url('./assets/fonts/taxX50l4.woff') format('woff'), url('./assets/fonts/taxX50l4.ttf') format('truetype')
}

