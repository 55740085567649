.live_info_table_container{
    background: url(../../assets/images/Liv_01.png);
    background-repeat: no-repeat;
    background-position: center center;
    width: 700px;
    background-size: contain;
    height: 500px;
    margin: 0 auto;
    margin-top: 100px;
    position: relative;
}

.live_info_table{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}

.live_info_table{
  width: 100%;
  max-width:500px;
}

.live_info_row{
  display: inline-flex;
  width: 100%
}

.live_info_title{
  color: #c593f9;
  font-size:22px;
  font-weight: bold;
  text-align: right;
  width:30%;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.live_info_result{
  color: #f0f1f2;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  width:70%;
  background: rgb(30,34,55);
  background: linear-gradient(180deg, rgba(30,34,55,1) 0%, rgba(30,34,55,1) 75%, rgba(124,113,156,1) 100%); 
  padding: 10px 0;				  
}

.label_live_info{
width: 100%;
max-width: 450px;
display: inline-block;
position: absolute;
top: -130px;
left: 50%;
transform: translateX(-50%);
}

.live_info_label_text{
font-size: 35px;
position: absolute;
top: 125px;
display: inline-block;
width: 100%;
margin: 0 auto;
text-align: center;
color: #c593f9;
font-weight: bold;
}

.live_info_img{
  text-align: center;
}

.the_maze_container {
    max-width: 1750px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
  