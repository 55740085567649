@import "../../../../styles/variables.scss";
.how-top-play-container {
  position: absolute;
  top: 20px;
  left: 50px;
  cursor: pointer;
}
.how-to-play{
  color: #fff;
  font-size: 24px;
  border: 2px solid #5455a1;
  background-color: #25214a;
  padding: 10px 10px 10px 35px;
  border-radius: 11px;
  position: relative;
}

.how-top-play-container:before {
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../../../assets/images/question_icon.png);
  position: absolute;
  left: -20px;
  width: 70px;
  height: 52px;
  z-index: 1;
  animation-duration: 1.25s;
  animation-name: question_animation;
  animation-timing-function: cubic-bezier(0.33, 0.19, 0.04, 2.07);
}
@keyframes question_animation {
  0%   {transform: scale(0);}
  100% {transform: scale(1) rotate(360deg);}
}
